import './index.module.scss';
import Layout from '../../layout';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Newsletter from '../../components/newsletter/newsletter';
import FixedBanner from '../../components/fixed-banner/fixed-banner';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <FixedBanner
        containerSm={true}
        formComponent={true}
        heading={<h2 className="large-heading">Contact us</h2>}
        content={
          <div>
            <h3 className="medium-heading">
              Discover how we can help you achieve your goals.
            </h3>
            <p className="norma-size-font">
              <strong className="reg-size-font">
                Thanks for wanting to learn more about how we’ll listen first,
                then partner with you to create a financial strategy designed to
                change along with your life.
              </strong>
              <br />
              <br />
            </p>
          </div>
        }
      />
      <Newsletter />
      <Footer />
    </Layout>
  );
}

export default Index;
